import React, { useState } from 'react';
import './style.css';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_CURRENT_RADIO, SET_PLAYER } from '../../store/actions/constants';
import { IoPlay } from 'react-icons/io5';
import { TiMediaPause } from 'react-icons/ti';
import Loading from '../Loading';
import { getStreamsRadioById } from '../../store/actions/radio';
import Trackable from '../../rp_sdk/tracking_components/Trackable/trackable';
import Listening from '../../rp_sdk/tracking_components/Listening/listening';

const RadioItem = ({ item, isListing, page }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);
	const dispatch = useDispatch();
	const { stream, playing, isRadio } = useSelector((state) => state.player);
	const { innerWidth: width } = window;
	const groupId = localStorage.getItem('uuid');
	const currentRadio = {
		nom: item.nom,
		description: item.description,
		rpID: item.rpID,
		image: item.image ? item.image.url : null,
		stream: item.stream,
		url: '',
	};

	const handleOnClick = (e) => {
		if (e.target.localName === 'div') {
			dispatch({ type: SET_CURRENT_RADIO, payload: currentRadio });
			navigate(`/radio/${item.rpID}-${encodeURI(item.nom?.trim()?.replace(/ /g, '-'))}`);
		}
	};

	const handlePlay = async () => {
		const startPlaying = !isRadio || !(playing && stream.rpID === item.rpID);
		const isNewRadioPlaying = playing && !(stream.rpID === item.rpID);
		if (isNewRadioPlaying) {
			const playButton = document.getElementById('listening_stop');
			if (playButton) playButton.click();
		}
		if (startPlaying) {
			dispatch(await getStreamsRadioById(currentRadio.rpID, navigate));
		}
		const payload = {
			playing: startPlaying,
			stream: currentRadio,
			playedSeconds: 0,
			isRadio: true,
			userClick: true,
		};
		if (!startPlaying) payload.lastRpidPlayed = stream.rpID;
		dispatch({ type: SET_PLAYER, payload: payload });
	};

	if (isListing && width <= 768) {
		return (
			<div className="radioItemListing">
				<div
					id={`radio${item?.rpID}`}
					name="redirectButton"
					onClick={(e) => handleOnClick(e)}
					className={`radioItemCon page-${page}`}
					style={{ backgroundImage: `url(${item?.image?.url})` }}
				>
					<img
						alt={item.nom}
						src={item?.image?.url}
						onLoad={() => setLoading(false)}
						onError={() => setInvalid(true)}
					/>
					<Loading
						size={30}
						color="red"
						conClass="radioItemLoading"
						style={{ opacity: loading && !invalid ? 1 : 0 }}
					/>
					<Trackable name="ListeningTracking">
						<Listening
							fire_START_on={{
								tag: 'Button',
								event: 'onClick',
								id: 'listening_radio_small_start',
								sync_info: {
									groupId: groupId,
									previouslyDevicePlatform: 'RPWGCR',
									currentlyPlayed: playing,
								},
							}}
							fire_STOP_on={{ tag: 'Button', event: 'onClick', id: 'listening_radio_small_stop' }}
							media_id={item?.rpID?.toString()}
							media_type={'STATION'}
							position="0"
							output="speakers"
						/>
						{playing && stream.rpID === item.rpID && isRadio ? (
							<Button variant="light" onClick={handlePlay} id="listening_radio_small_stop">
								<TiMediaPause color="#000" size={20} />
							</Button>
						) : (
							<Button variant="light" onClick={handlePlay} id="listening_radio_small_start">
								<IoPlay color="#000" size={15} />
							</Button>
						)}
					</Trackable>
				</div>
				<div className="radioItemListingContent">
					<h2>{item.nom}</h2>
					<p>{item?.description?.substr(0, 80)}...</p>
				</div>
			</div>
		);
	} else {
		return (
			<div
				id={`radio${item?.rpID}`}
				name="redirectButton"
				onClick={(e) => handleOnClick(e)}
				className={`radioItemCon page-${page}`}
				style={{ backgroundImage: `url(${item?.image?.url})` }}
			>
				<img
					alt={item.nom}
					src={item?.image?.url}
					onLoad={() => setLoading(false)}
					onError={() => setInvalid(true)}
				/>
				<Loading
					size={30}
					color="red"
					conClass="radioItemLoading"
					style={{ opacity: loading && !invalid ? 1 : 0 }}
				/>
				<Trackable name="ListeningTracking">
					<Listening
						fire_START_on={{
							tag: 'Button',
							event: 'onClick',
							id: 'listening_radio_start',
							sync_info: {
								groupId: groupId,
								previouslyDevicePlatform: 'RPWGCR',
								currentlyPlayed: playing,
							},
						}}
						fire_STOP_on={{ tag: 'Button', event: 'onClick', id: 'listening_radio_stop' }}
						media_id={item?.rpID?.toString()}
						media_type={'STATION'}
						position="0"
						output="speakers"
					/>
					{playing && stream.rpID === item.rpID && isRadio ? (
						<Button variant="light" onClick={handlePlay} id="listening_radio_stop">
							<TiMediaPause color="#000" size={20} />
						</Button>
					) : (
						<Button variant="light" onClick={handlePlay} id="listening_radio_start">
							<IoPlay color="#000" size={15} />
						</Button>
					)}
				</Trackable>
			</div>
		);
	}
};

export default RadioItem;
