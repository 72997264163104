import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile, isSafari } from 'react-device-detect';
import './assets/css/App.css';
import Navigator from './navigation';
import packageJson from '../package.json';
import { setPlayer } from './store/actions/player';
import { persistor } from './store';
import { SET_PLAYER } from './store/actions/constants';
import { Footer, Header } from './parts';
import { Player } from './components';
import { MobileBanner } from './components';
import { start_tracking, updateCountryCode, updateNetworkInfo } from './rp_sdk/rpsdk.min';
import { v4 as uuidv4 } from 'uuid';

const App = () => {
	const dispatch = useDispatch();
	const [isBannerOpened, setIsBannerOpened] = useState(false);
	const version = localStorage.getItem('version');
	const lastRadioListenedRpId = localStorage.getItem('lastRadioListened');
	const groupId = uuidv4();

	function resetStore() {
		persistor.purge();
		dispatch({
			type: SET_PLAYER,
			payload: {},
		});
	}
	useEffect(() => {
		if (groupId) {
			localStorage.setItem('uuid', groupId);
		}
	}, [groupId]);
	useEffect(() => {
		if (isMobile) {
			setIsBannerOpened(true);
		}
	}, []);
	useEffect(() => {
		const config = {
			appName: 'radioplayer-fr-site',
			appVersion: '1.4.0',
			countryCode: '380',
			networkInfo: {
				name: 'RP-MOBILE',
				type: 'MOBILE',
			},
			geoMode: 'auto',
			debug: true,
		};
		start_tracking(config);
		updateCountryCode('250');
		const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
		if (connection) {
			let type = '';
			let networkName = '';
			if (
				connection.type === 'cellular' ||
				connection.effectiveType === '4g' ||
				connection.effectiveType === '3g'
			) {
				type = 'MOBILE';
				networkName = 'RP-MOBILE';
			} else if (connection.type === 'wifi' || connection.effectiveType === 'wifi') {
				type = 'WIFI';
				networkName = 'RP-WIFI';
			} else {
				type = 'WIRED';
				networkName = 'RP-WIRED';
			}
			updateNetworkInfo({ name: networkName, type: type });
		}
	}, []);
	const caching = () => {
		if (version !== packageJson.version) {
			localStorage.setItem('lastRadioListened', '');
			resetStore();
			if ('caches' in window) {
				caches.keys().then((names) => {
					names.forEach((name) => {
						caches.delete(name);
					});
				});

				window.location.reload(true);
			}
			localStorage.clear();
			localStorage.setItem('version', packageJson.version);
		}
	};

	const getLastRadioListened = () => {
		dispatch(setPlayer({ playing: false }));
		if (lastRadioListenedRpId) {
			document.querySelector('.playerContainer').style.bottom = '0px';
		} else if (!lastRadioListenedRpId) {
			document.querySelector('.playerContainer').style.bottom = '-100px';
		}
	};

	window.onload = () => {
		caching();
		getLastRadioListened();
	};

	return (
		<div className="App">
			{isMobile && isBannerOpened && !isSafari ? <MobileBanner setIsBannerOpened={setIsBannerOpened} /> : null}
			<Header isBannerOpened={isBannerOpened} />
			<Navigator />
			<Footer />
			<Player />
		</div>
	);
};

export default App;
