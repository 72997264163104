import React from 'react';
import './style.css';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { about4, ads, frame1 } from '../../assets/images';
import translate from '../../translation';

const About = () => {
	return (
		<div id="proposPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section1">
				<Container>
					<h1>Radioplayer France, bien plus que de la radio</h1>
				</Container>
			</section>
			<section className={`section2`}>
				<Container>
					<p className="mb-5">
						Radioplayer France a été lancé par 6 groupes de radios Lagardère News, Les Indés Radios, NRJ
						Group, Radio France, RMC BFM, et le groupe M6/RTL.
						<br />
						La plateforme est ouverte à l’ensemble des radios qui le souhaitent. Elle accueille aujourd’hui
						320 radios, près de 1000 webradios et plus de 200 000 podcasts. L’objectif de Radioplayer France
						est de proposer une interface digitale simple et gratuite permettant d’écouter l’ensemble des
						programmes radios et contenus audio des radios sur les différents appareils numériques,
						notamment en mobilité.
						<br />
						<br />
						Radioplayer France appartient au réseau Radioplayer qui regroupe 23 pays unis par le biais d’une
						plateforme technique et d’une marque commune pour renforcer la place de la radio dans l’univers
						digital.{' '}
					</p>
					<div className="d-flex justify-content-center">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/dgZJaNmRhW8"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerpolicy="strict-origin-when-cross-origin"
							allowfullscreen
						></iframe>
					</div>
					<h1>Des centaines de radios et des milliers de contenus audio</h1>
					<p>
						Radioplayer France, c’est l’accès à presque toute la scène radio française et à tous leurs
						contenus audio d’un seul coup d’œil. Une offre de contenus unique et exhaustive qui permet aux
						différentes radios, réunies, d’être des acteurs incontournables.
					</p>
					<h5>Des contenus live</h5>
					<p>
						Plus de 250 radios et 930 webradios sont disponibles et accessibles très facilement sur l’appli
						Radioplayer France. L’intégration à Radioplayer France est possible pour toute radio possédant
						au moins une fréquence FM ou DAB+. C’est pourquoi tout le paysage radio français est représenté.
					</p>
					<h5>Des contenus locaux </h5>
					<p>
						Radioplayer France, c’est aussi des contenus locaux que l’on ne retrouve pas ailleurs.
						Radioplayer France est aujourd’hui la seule appli d’agrégation qui permet d’écouter les radios
						locales et les décrochages locaux. Parce que c’est important de retrouver sa radio, ses infos où
						que l’on soit et de garder un lien avec son territoire. D’autant que l’appli recommande les
						radios et contenus audio géolocalisés en fonction de l’endroit où se trouve l’auditeur.Cette
						offre unique s’appuie sur la diversité de notre paysage radio, un des plus riches au monde.
					</p>
					<h5>Des contenus audio par milliers</h5>
					<p>
						Radioplayer France donne accès à l’ensemble du catalogue des podcasts créés par les radios,
						c’est plus de 90% des podcasts français ! Il y en a pour tous les goûts : de la réécoute de son
						émission radio préférée aux podcasts natifs sur tous les sujets, de toutes les sortes,
						Radioplayer France est un compagnon du quotidien.
					</p>
					<div className="text-center mb-5">
						<img src={about4} className="cover-img3" alt="" />
					</div>
					<h1>Radioplayer France, la seule plateforme officielle et gratuite d’écoute de la radio </h1>
					<p>
						Radioplayer France est la seule appli d’écoute légale de la radio, puisque chaque radio a choisi
						de rejoindre l’écosystème Radioplayer France. Appli officielle des radios, Radioplayer France
						est bien sûr gratuite comme le sont les radios, mais c’est aussi une appli qui garantit une
						qualité radio !
					</p>
					<h5>Peu de publicité</h5>
					<p>
						Seule la publicité présente nativement sur chaque radio existe, Radioplayer France ne rajoute
						pas une nouvelle couche publicitaire.{' '}
					</p>
					<h5>Une meilleure qualité du son en mobilité</h5>
					<p>
						Radioplayer France intègre la norme HLS qui va permettre une meilleure qualité d’écoute. En
						effet, à la différence du streaming audio classique, le HLS est un format qui est parfaitement
						adapté au mobile. Lorsque le téléphone de l’auditeur a une coupure réseau, en cas de reprise,
						l’application va reprendre la lecture là où elle s’était arrêtée. De plus, le HLS permet
						d’adapter la qualité du son pour avoir le meilleur son possible.{' '}
					</p>
					<p>
						Le HLS a un autre avantage. Dans un second temps, il va permettre la mise en place de système
						d’accès sécurisé pour éviter la reprise sauvage des flux de streaming des radios. La radio
						reprend en main sa distribution !
					</p>
					<div className="text-center mb-5">
						<img src={ads} className="cover-img2" alt="" />
					</div>
					<h5>Radioplayer France, une expérience innovante</h5>
					<p>
						Interface intuitive, simple et gratuite, Radioplayer France a été pensée par des radios qui ont
						l’habitude de se mettre à la place de l’auditeur et d’anticiper leurs besoins. Le résultat : des
						innovations addictives qui révolutionnent l’expérience d’écoute.
					</p>
					<h5>Un modèle prédictif basé sur l’écoute réelle des auditeurs</h5>
					<p>
						Radioplayer France intègre un algorithme de recommandation très spécifique qui s’adapte aux
						goûts réels des auditeurs. Concrètement, un utilisateur qui télécharge l’application Radioplayer
						France et l’ouvre pour la 1ère fois va pouvoir sélectionner dès le premier écran ses radios
						favorites afin de les retrouver ultérieurement dans l’application. A partir de cette sélection,
						l’algorithme va proposer un certain nombre d’autres radios en fonction de différents critères
						dont :
						<br />
						La duplication Médiamétrie (issue de l’étude d’audience 126 000, c’est-à-dire l’identification
						des différentes radios écoutées par un auditeur) ;
						<br />
						La géolocalisation ;
						<br />
						Les tendances d’écoute au sein de l’application.
					</p>
					<h5>La fonction « start over »</h5>
					<p>
						La fonction rembobiner ou « start over » permet de revenir au début d’une émission jusqu'à 4h en
						arrière. Cette option est activée progressivement sur toutes les stations.
					</p>
					<h5>L’écoute augmentée</h5>
					<p>
						Radioplayer France offre une écoute immersive via la mise à disposition des données associées et
						des métadonnées des radios. Ainsi les auditeurs pourront-ils avoir accès pendant leur écoute au
						nom des titres et des artistes en cours de diffusion, à la grille des programmes à 7 jours avec
						les détails des émissions et au replay des émissions.
					</p>
					<div className="text-center mb-5">
						<img src={frame1} className="cover-img1" alt="" />
					</div>
					<h1>Radioplayer France, une expérience simple, accessible partout</h1>
					<p>
						Radioplayer France est une appli avec une interface digitale simple et gratuite accessible
						partout   pour une expérience sans-couture quelles que soient les interfaces (web, mobile,
						objets connectés, etc.){' '}
					</p>
					<h5>Les enceintes connectées, un enjeu de développement</h5>
					<p>
						Radioplayer France a vocation à être reprise sur l’ensemble des carrefours d’audience connectés,
						box, consoles, et autres objets connectés et bien sûr les enceintes connectées. Radioplayer
						France est présent en natif sur Amazon Alexa, il suffit de demander le nom de la station pour
						lancer le flux, et prochainement sur l’Assistant Google.
					</p>
					<h5>Une écoute de la radio fluide en voiture</h5>
					<p>
						Radioplayer est accessible dans la voiture par le biais d’applications dédiées à la voiture
						(Apple Carplay et Android Auto). <br />
						L’objectif de Radioplayer est d’être également accessible via les systèmes propriétaires
						utilisés par les constructeurs. Radioplayer a mis en place des accords de long terme avec des
						constructeurs automobiles comme Renault, le groupe Volkswagen, BMW et Polestar (Volvo).
						L’objectif est de maintenir un bouton radio au sein de la voiture en intégrant l’appli Android
						Automotive reprises au sein des véhicules qui sortiront des usines à partir de 2023. <br />
						<br />
						Radioplayer dans Android Automotive offre une nouvelle fonctionnalité : la mise en place la
						radio hybride. Elle permet à un utilisateur d’écouter au sein d’un environnement unique des
						radios DAB+, FM ou via internet en fonction de la meilleure qualité de diffusion disponible.
						Ainsi, toutes les stations de radios deviennent accessibles dans tout le pays quelle que soit
						leur zone de diffusion.
						<br />
						La technologie de radio hybride proposée par Radioplayer est unique et soutenue par des
						fabricants d’autoradio comme Panasonic.
					</p>
				</Container>
			</section>
		</div>
	);
};

export default About;
