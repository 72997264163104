import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import { SET_PLAYER } from '../../store/actions/constants';
import { Col, Button } from 'react-bootstrap';
import { IoPlay } from 'react-icons/io5';
import { TiMediaPause } from 'react-icons/ti';
import Trackable from '../../rp_sdk/tracking_components/Trackable/trackable';
import Listening from '../../rp_sdk/tracking_components/Listening/listening';

const PodcastSerie = ({ item }) => {
	const { stream, playing, stream: playedPodcast } = useSelector((state) => state.player);
	const groupId = localStorage.getItem('uuid');
	const dispatch = useDispatch();
	const handleOnPlay = () => {
		const isNewRadioPlaying = playing && !(stream.rpID === playedPodcast.id);
		if (isNewRadioPlaying) {
			const playButton = document.getElementById('listening_stop');
			if (playButton) playButton.click();
		}
		const payload = {
			playing: !(playing && playedPodcast.url === item.audio_url),
			stream: {
				nom: item.mediumName,
				description: item.longName,
				rpID: item.rpID,
				image: item.image,
				stream: item.audio_url,
				url: item.audio_url,
				id: item.id,
			},
			playedSeconds: 0,
			deltaTime: 0,
			userClick: true,
			isRadio: false,
		};

		dispatch({ type: SET_PLAYER, payload: payload });
	};
	return (
		<Col md={{ span: 10, offset: 1 }}>
			<div className="episodeItem">
				<div className="episodeItemCard bg-white" style={{ backgroundImage: `url(${item?.image})` }} />
				<div className="episodeItemContent">
					<h2>{item.longName}</h2>
					<p>{item.longDescription}</p>
				</div>
				<Trackable name="ListeningTracking">
					<Listening
						fire_START_on={{
							tag: 'Button',
							event: 'onClick',
							id: 'listening_episode_start',
							sync_info: {
								groupId: groupId,
								previouslyDevicePlatform: 'RPWGCR',
								currentlyPlayed: playing,
							},
						}}
						fire_STOP_on={{ tag: 'Button', event: 'onClick', id: 'listening_episode_stop' }}
						media_id={item?.id?.toString()}
						media_type={'EPISODE'}
						position="0"
						output="speakers"
					/>

					{playing && playedPodcast.url === item.audio_url ? (
						<Button className="btn-play-mini" onClick={handleOnPlay} id="listening_episode_stop">
							<TiMediaPause color="#000" size={20} />
						</Button>
					) : (
						<Button className="btn-play-mini" onClick={handleOnPlay} id="listening_episode_start">
							<IoPlay color="#000" size={20} />
						</Button>
					)}
				</Trackable>
			</div>
		</Col>
	);
};

export default PodcastSerie;
