import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_PLAYER } from '../../store/actions/constants';
import './style.css';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import Trackable from '../../rp_sdk/tracking_components/Trackable/trackable';
import Listening from '../../rp_sdk/tracking_components/Listening/listening';

const { REACT_APP_DEFAULT_PODCAST_IMAGE } = process.env;

function PodcastItem({ podcast, isEpisode }) {
	const { stream, playing, stream: playedPodcast } = useSelector((state) => state.player);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);
	const groupId = localStorage.getItem('uuid');

	const handleOnClick = (idseries) => {
		navigate(`/podcast/${idseries}`);
	};

	const handleOnPlay = () => {
		const isNewRadioPlaying = playing && !(stream.rpID === playedPodcast.id);
		if (isNewRadioPlaying) {
			const playButton = document.getElementById('listening_stop');
			if (playButton) playButton.click();
		}
		const payload = {
			playing: !(playing && playedPodcast.url === podcast.audio_url),
			stream: {
				nom: podcast.mediumName,
				description: podcast.longName,
				rpID: podcast.rpID,
				image: podcast.image,
				url: podcast.audio_url,
				id: podcast.id,
			},
			playedSeconds: 0,
			deltaTime: 0,
			userClick: true,
			isRadio: false,
		};
		dispatch({ type: SET_PLAYER, payload: payload });
	};
	return (
		<div
			key={podcast.id}
			className="responsive-image-container-top"
			onClick={() => {
				handleOnClick(podcast.idseries || podcast.feedID || podcast.id);
			}}
		>
			<Loading
				size={30}
				color="red"
				conClass="podcastAlauneLoading"
				style={{
					opacity: loading && !invalid ? 1 : 0,
					position: 'absolute',
					top: '50%',
					height: '30px',
					zIndex: '99',
				}}
			/>
			<img
				src={!invalid ? podcast.imageUrl || podcast.image : REACT_APP_DEFAULT_PODCAST_IMAGE}
				alt={`Slide ${podcast.seriesId}`}
				className="responsive-image-top"
				onLoad={() => setLoading(false)}
				onError={() => setInvalid(true)}
				style={{ opacity: invalid ? 0.7 : 1 }}
			/>
			{isEpisode && (
				<div className="podcastItemPlayBtnWrapper" variant="primary">
					<Trackable name="ListeningTracking">
						<Listening
							fire_START_on={{
								tag: 'div',
								event: 'onClick',
								id: 'listening_single_episode_start',
								sync_info: {
									groupId: groupId,
									previouslyDevicePlatform: 'RPWGCR',
									currentlyPlayed: playing,
								},
							}}
							media_id={podcast?.id?.toString()}
							media_type={'EPISODE'}
							position="0"
							output="speakers"
						/>
						<div
							className="podcastItemPlayBtn"
							onClick={() => {
								handleOnClick(podcast.idseries || podcast.feedID || podcast.id);
								isEpisode && handleOnPlay();
							}}
							id="listening_single_episode_start"
						>
							<IoPlay size={15} color="#000" />
						</div>
					</Trackable>
				</div>
			)}
			{invalid && <span className="podcastItemLegend">{podcast.title || podcast.mediumName}...</span>}
		</div>
	);
}

export default PodcastItem;
